exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-envases-js": () => import("./../../../src/pages/envases.js" /* webpackChunkName: "component---src-pages-envases-js" */),
  "component---src-pages-filosofia-js": () => import("./../../../src/pages/filosofia.js" /* webpackChunkName: "component---src-pages-filosofia-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-nosotros-js": () => import("./../../../src/pages/nosotros.js" /* webpackChunkName: "component---src-pages-nosotros-js" */),
  "component---src-pages-producto-adultomyg-js": () => import("./../../../src/pages/producto_adultomyg.js" /* webpackChunkName: "component---src-pages-producto-adultomyg-js" */),
  "component---src-pages-producto-adultomyp-js": () => import("./../../../src/pages/producto_adultomyp.js" /* webpackChunkName: "component---src-pages-producto-adultomyp-js" */),
  "component---src-pages-producto-cachorros-js": () => import("./../../../src/pages/producto_cachorros.js" /* webpackChunkName: "component---src-pages-producto-cachorros-js" */),
  "component---src-pages-producto-cordero-js": () => import("./../../../src/pages/producto_cordero.js" /* webpackChunkName: "component---src-pages-producto-cordero-js" */),
  "component---src-pages-producto-gatosadultos-js": () => import("./../../../src/pages/producto_gatosadultos.js" /* webpackChunkName: "component---src-pages-producto-gatosadultos-js" */)
}

